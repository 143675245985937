import Header from "../components/header"
import { useState } from "react";
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { ethers, utils, BigNumber } from 'ethers';
import { PresaleABI } from "../PresaleABI";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Countdown from "../components/countdown";

const PresaleContractAddress = '0x60948122d49E0612caa6E1081C3A4b189a985893';

export default function Home() {

    const providerUrl = 'https://rpc.blast.io';
    const provider = new ethers.providers.JsonRpcProvider(providerUrl);

    const [progressWidth, setProgressWidth] = useState(0);

    const [minAmount, setMinAmount] = useState("...")
    const [maxAmount, setMaxAmount] = useState("...")
    const [maxPerWallet, setMaxPerWallet] = useState("...");
    const [hardCap, setHardCap] = useState("...");
    const [hardCapInt, setHardCapInt] = useState("...");
    const [amountRaised, setAmountRaised] = useState("...");
    const [totalRaised, setTotalRaised] = useState("...");
    const [totalContributors, setTotalContributors] = useState("...");
    const [rate, setRate] = useState("...");
    const [userContribution, setUserContribution] = useState("...");
    const [totalPurchased, setTotalPurchased] = useState("...");
    const [userBalance, setUserBalance] = useState("...");
    const [amountToGet, setAmountToGet] = useState("...");
    const [rateInt, setRateInt] = useState(0);
    const [loading, setLoading] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()

    const [contributionAmount, setContributionAmount] = useState('');
    const [presaleDate, setPresaleDate] = useState("");
    const [presaleStarted, setPresaleStarted] = useState(false);

    async function initialize() {
        try {
            //setUserBalance("...");
            //setUserContribution("...");

            const PresaleContract = new ethers.Contract(PresaleContractAddress, PresaleABI, provider);
            const minAmount = await PresaleContract.minAmount();
            const maxAmount = await PresaleContract.maxAmount();
            const maxPerWallet = await PresaleContract.maxAmountPerWallet();
            const hardCap = await PresaleContract.hardCap();
            const totalRaised = await PresaleContract.totalRaised();
            const totalContributors = await PresaleContract.getTotalContributors();
            const rate = await PresaleContract.pricePerToken();
            const presaleDate = await PresaleContract.startTime();

            setMinAmount(`${utils.formatEther(minAmount)} ETH`)
            setMaxAmount(`${utils.formatEther(maxAmount)} ETH`)
            setMaxPerWallet(`${utils.formatEther(maxPerWallet)} ETH`);
            setHardCap(`${utils.formatEther(hardCap)} ETH`);
            setHardCapInt(utils.formatEther(hardCap));
            setAmountRaised(`${utils.formatEther(totalRaised)} ETH`);
            setTotalRaised(utils.formatEther(totalRaised));
            setTotalContributors(totalContributors.toString());
            setRate(`${utils.formatUnits(rate)} $BYTE / ETH `);
            setRateInt(utils.formatUnits(rate));
            const epochTimestamp = 1710361829;
            const targetDate = new Date(presaleDate * 1000).toISOString().slice(0, 19);

            //const date = new Date(presaleDate * 1000);

            //const targetDate = date.toISOString().slice(0, 19);
            console.log("Target date is: " + targetDate);
            setPresaleDate(targetDate.toString() + "Z");

            if (isConnected) {
                const userContributions = await PresaleContract.getUserContributions({ from: address });
                let totalWei = ethers.BigNumber.from(0);

                for (let i = 0; i < userContributions.length; i++) {
                    const contributionAmount = userContributions[i][1];
                    console.log(contributionAmount._hex)
                    totalWei = totalWei.add(ethers.BigNumber.from(contributionAmount._hex));
                }

                const totalEth = ethers.utils.formatEther(totalWei);
                const userBalance = await PresaleContract.userBalance(address);
                console.log(userBalance)
                setUserBalance(`${utils.formatEther(userBalance)} BYTE`);
                setUserContribution(`${totalEth} ETH`);
                console.log(userContribution)
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function contribute() {
        if (!isConnected) {
            toast.error("Wallet not connected", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        if (contributionAmount == "") {
            toast.error("Enter contribution amount", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        setLoading(true);
        try {
            const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
            const signer = await ethersProvider.getSigner()
            // The Contract object
            console.log(contributionAmount);
            const PresaleContract = new ethers.Contract(PresaleContractAddress, PresaleABI, signer)
            const tx = await PresaleContract.contribute({ value: utils.parseEther(contributionAmount.toString()) })
            toast.success('Transaction submitted successfully and is currently pending confirmation!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            const receipt = await tx.wait();
            if (receipt.status === 0) {
                console.error("Transaction failed with status 0");
                toast.error("Transaction failed", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
            } else {
                console.log(`Transaction successful: ${receipt.transactionHash}`);
                toast.success('Your contribution was successful, thanks!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            setContributionAmount("");
            initialize();
            setLoading(false);
            console.log(tx)
        } catch (error) {
            let message = '';
            if (error.code === ethers.errors.UNPREDICTABLE_GAS_LIMIT) {
                if (error.error && error.error.data) {
                    message = error.error.data.message;
                } else {
                    message = error;
                }
            } else if (error.code == "INVALID_ARGUMENT") {
                message = "An error occurred, invalid argument!";
            } else if (error.code == "ACTION_REJECTED") {
                message = "Transaction cancelled!";
            } else if (error.code == "-32603") {
                message = "Transaction error";
            }
            else {
                // Handle other types of errors
                console.error('Non-gas estimation error:', error);
            }
            toast.error(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.log(error)
            console.log(error.code)
            setLoading(false);
        }
    }

    useEffect(() => {
        if (isConnected) {
            initialize();
        }
    }, [isConnected, chainId, address])

    useEffect(() => {
        if (contributionAmount > 0) {
            setAmountToGet(`${utils.formatEther(utils.parseEther(contributionAmount)) * rateInt} $BYTE`)
        } else {
            setAmountToGet("...")
        }
    }, [contributionAmount, rateInt])

    useEffect(() => {
        console.log(amountRaised)
        console.log("Hard Cap: " + hardCapInt);
        console.log("Total raised: " + totalRaised);
        setProgressWidth(Math.min(totalRaised * 100 / hardCapInt, 100))
    }, [amountRaised, hardCapInt])

    useEffect(() => {
        initialize();
        const intervalId = setInterval(() => {
            if(!presaleStarted) return
            initialize();
        }, 40000);
        return () => clearInterval(intervalId);
    }, [])
    const handleTimeLeftChange = (timeLeft) => {

        const { days, hours, minutes, seconds } = timeLeft;

        if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {
            //console.log("Countdown has ended")
            setPresaleStarted(true);
            return;
        }

        //console.log("Time left:", timeLeft); // Do something with timeLeft in the parent component
    };

    return (
        <>
            <Header />
            <div className="container mx-auto p-4">
                {/* Flex container for the two columns */}
                <div className="flex flex-col items-center">
                    <span style={{ color: "#fff", fontFamily: "iceland", fontSize: "25px", fontWeight: "bolder" }}>Presale Starts In</span>
                    {presaleDate &&
                        <Countdown targetDate={presaleDate} onTimeLeftChange={handleTimeLeftChange} />
                    }
                </div>
            </div>
            <div className="container mx-auto p-4">
                {/* Flex container for the two columns */}
                <div className="flex flex-col md:flex-row">

                    {/* Left Column */}
                    <div className="md:w-1/2 p-4">
                        <h2 className="font-bold text-lg mb-4 text-white" style={{ fontFamily: "Paladins" }}>Byte on Blast Presale</h2>

                        <div className="mt-4 rounded-lg flex max-width-md-200 flex-row justify-between items-center px-5 py-2" style={{ background: 'linear-gradient(to right, #FFCF3E,#FFEAA1 )' }}>
                            <div style={{ fontWeight: "bold", fontFamily: "iceland" }}>
                                Min Amount
                            </div>
                            <div style={{ fontFamily: "iceland" }}>
                                {minAmount}
                            </div>
                        </div>

                        <div className="mt-2 rounded-lg flex max-width-md-200 flex-row justify-between items-center px-5 py-2" style={{ background: 'linear-gradient(to right, #FFCF3E,#FFEAA1 )' }}>
                            <div style={{ fontWeight: "bold", fontFamily: "iceland" }}>
                                Max Amount
                            </div>
                            <div style={{ fontFamily: "iceland" }}>
                                {maxAmount}
                            </div>
                        </div>

                        <div className="mt-2 rounded-lg flex max-width-md-200 flex-row justify-between items-center px-5 py-2" style={{ background: 'linear-gradient(to right, #FFCF3E,#FFEAA1 )' }}>
                            <div style={{ fontWeight: "bold", fontFamily: "iceland" }}>
                                Max Per Wallet
                            </div>
                            <div style={{ fontFamily: "iceland" }}>
                                {maxPerWallet}
                            </div>
                        </div>

                        <div className="mt-2 rounded-lg flex max-width-md-200 flex-row justify-between items-center px-5 py-2" style={{ background: 'linear-gradient(to right, #FFCF3E,#FFEAA1 )' }}>
                            <div style={{ fontWeight: "bold", fontFamily: "iceland" }}>
                                Hard cap
                            </div>
                            <div style={{ fontFamily: "iceland" }}>
                                {hardCap}
                            </div>
                        </div>

                        <div className="mt-2 rounded-lg flex max-width-md-200 flex-row justify-between items-center px-5 py-2" style={{ background: 'linear-gradient(to right, #FFCF3E,#FFEAA1 )' }}>
                            <div style={{ fontWeight: "bold", fontFamily: "iceland" }}>
                                Amount Raised
                            </div>
                            <div style={{ fontFamily: "iceland" }}>
                                {amountRaised}
                            </div>
                        </div>

                        <div className="mt-2 rounded-lg flex max-width-md-200 flex-row justify-between items-center px-5 py-2" style={{ background: 'linear-gradient(to right, #FFCF3E,#FFEAA1 )' }}>
                            <div style={{ fontWeight: "bold", fontFamily: "iceland" }}>
                                Total Contributors
                            </div>
                            <div style={{ fontFamily: "iceland" }}>
                                {totalContributors}
                            </div>
                        </div>

                    </div>

                    {/* Right Column */}
                    <div className="md:w-1/2 p-4">

                        <div className="mt-4 flex flex-col p-5 rounded-lg bg-[#FFEAA1]">

                            <div className="mt-0 flex max-width-md-200 flex-row justify-between items-center rounded-lg px-5 py-2" style={{ background: 'linear-gradient(to right, #FFCF3E,#FFCF3E )' }}>
                                <div>
                                    <span style={{ fontWeight: "bold", fontSize: "18px", fontFamily: "iceland" }}>Rate</span>
                                </div>
                                <div style={{ fontFamily: "iceland", fontSize: "18px" }}>
                                    {rate}
                                </div>
                            </div>

                            <div className="mt-1 flex max-width-md-200 flex-row justify-between items-center rounded-lg px-5 py-2" style={{ background: 'linear-gradient(to right, #FFCF3E,#FFCF3E )' }}>
                                <div>
                                    <span style={{ fontWeight: "bold", fontSize: "18px", fontFamily: "iceland" }}>Your contribution</span>
                                </div>
                                <div style={{ fontFamily: "iceland", fontSize: "18px" }}>
                                    {userContribution}
                                </div>
                            </div>

                            <div className="mt-1 flex max-width-md-200 flex-row justify-between items-center rounded-lg px-5 py-2" style={{ background: 'linear-gradient(to right, #FFCF3E,#FFCF3E )' }}>
                                <div>
                                    <span style={{ fontWeight: "bold", fontSize: "18px", fontFamily: "iceland" }}>Total $BYTE purchased</span>
                                </div>
                                <div style={{ fontFamily: "iceland", fontSize: "18px" }}>
                                    {userBalance}
                                </div>
                            </div>

                            <form className="flex flex-col mx-auto max-w-md gap-2 mt-5 ">

                                <div className="flex items-center mt-0 pb-2 border-b  border-[#FFCF3E]">
                                    <label htmlFor="email" className="block min-w-[80px] text-left mr-4" style={{ fontFamily: "iceland", fontSize: "18px" }}>Amount to Contribute (ETH)</label>
                                    <input
                                        type="number"
                                        className="p-2 w-[30%] rounded-lg text-center"
                                        placeholder="1"
                                        value={contributionAmount}
                                        onChange={e => setContributionAmount(e.target.value)}
                                    />
                                </div>

                                <div className="flex items-center mt-0  pb-2 border-b border-[#FFCF3E]">
                                    <label htmlFor="email" className="block w-[50%] text-left mr-4" style={{ fontFamily: "iceland", fontSize: "18px" }}>Amount of $BYTE tokens: </label>
                                    <span className="w-[30%]" style={{ fontFamily: "iceland", fontSize: "18px" }}>{amountToGet}</span>
                                </div>

                                <div className="w-full bg-gray-200 rounded-full h-4">

                                    <div
                                        className="bg-blue-600 h-4 rounded-full"
                                        style={{ width: `${progressWidth}%` }}
                                    ></div>

                                </div>

                            </form>

                            <div className="flex justify-center">
                                {isConnected && presaleStarted &&
                                    <button onClick={contribute} style={{ fontFamily: "iceland", fontSize: "18px" }} className="bg-[#c72227] hover:bg-[#da292e] mt-5 text-white font-bold py-2 px-4 rounded max-w-md mr-5" disabled={loading}>
                                        {loading ? "Check your wallet..." : "Contribute"}
                                    </button>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </>
    )
}